import { ThemeProvider } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import {
  GlobalStyles,
  createMaterialTheme,
  useScrollReset,
  LoadingScreen,
  THEMES
} from './design';
import routes from './routes';
import { useAuth } from './authentication';

export const App = () => {
  const content = useRoutes(routes);
  const auth = useAuth();
  useScrollReset();

  const theme = createMaterialTheme({
    direction: 'ltr',
    responsiveFontSizes: true,
    roundedCorners: false,
    theme: THEMES.LIGHT
  });

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {auth.isInitialized ? content : <LoadingScreen />}
    </ThemeProvider>
  );
};
