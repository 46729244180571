import {
  Box,
  Button,
  ButtonBase,
  Divider,
  Popover,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { TextAvatar } from '../TextAvatar';
import { useAuth } from 'authentication';

export const AccountPopover = () => {
  const anchorRef = useRef(null);
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const handleLogout = async () => {
    try {
      setOpen(false);
      await logout();
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={() => {
          setOpen(true);
        }}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
      >
        <TextAvatar name={user.name} />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        slotProps={{
          paper: { sx: { width: 240, mt: 2 } }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle1">
            {user?.name}
          </Typography>
          <Typography color="textSecondary" variant="subtitle2">
            {user?.email}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Button color="primary" onClick={handleLogout} variant="outlined">
            登　出
          </Button>
        </Box>
      </Popover>
    </>
  );
};
