/* eslint-disable no-unused-vars */
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useAuth } from '../hooks';

// TODO: replace it with a login form
export const LoginButton = () => {
  const [error, setError] = useState(null);
  const { login } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    const form = new FormData(e.currentTarget);
    try {
      await login({
        username: form.get('username'),
        password: form.get('password')
      });
      setError(null);
    } catch (err) {
      // Fetch error thrown by rtk-query
      if (err.status === 'FETCH_ERROR') {
        setError('服务器当前出现了一些问题，请重试。');
      } else {
        setError('登录失败：用户名或密码错误。');
      }
    }
  };

  return (
    <Box component="form" noValidate onSubmit={handleLogin}>
      {error && (
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      )}
      <TextField
        fullWidth={true}
        id="username"
        name="username"
        placeholder="用户名"
        margin="dense"
      ></TextField>
      <TextField
        fullWidth={true}
        id="password"
        name="password"
        type="password"
        placeholder="密　码"
        margin="dense"
      ></TextField>
      <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
        <Button
          color="primary"
          fullWidth={true}
          size="large"
          type="submit"
          variant="contained"
          sx={{ my: 1 }}
        >
          登　录
        </Button>
        <Button
          color="secondary"
          fullWidth={true}
          size="large"
          variant="contained"
          sx={{ my: 1 }}
        >
          注　册
        </Button>
      </Stack>
    </Box>
  );
};
