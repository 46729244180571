import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Drawer, IconButton, Box, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { GrSearch } from 'react-icons/gr';
import { MdDataset, MdChat, MdInsights, MdSettings } from 'react-icons/md';
import { PiSelectionPlus } from 'react-icons/pi';
import { SiJupyter } from 'react-icons/si';
import { useLocation } from 'react-router-dom';
import { NavSection } from '../NavSection';
import { repo } from 'utils/constants';

export const DefaultSidebar = ({ openDrawer, onOpenDrawerChange }) => {
  function initSections() {
    const searchSection = {
      title: '影像检索',
      path: '/search',
      icon: <GrSearch size={16} />
    };

    const bucketSection = {
      title: '我的收藏',
      path: '/favorite',
      icon: <MdDataset size={16} />
    };

    const toolboxSection = {
      title: '可视化工具箱',
      path: '/geotoolbox',
      icon: <MdInsights size={16} />
    };

    const journalSection = {
      title: 'GeoJournal',
      path: `/geojournal/repos/${repo.owner}/${repo.repo}`,
      icon: <SiJupyter size={16} />
    };

    const labelSection = {
      title: '影像分割和标注',
      path: '/labelling',
      icon: <PiSelectionPlus size={16} />
    };

    const autoSolverSection = {
      title: 'GeoCopilot',
      path: '/autosolver',
      icon: <MdChat size={16} />
    };

    const profileSection = {
      title: '设　置',
      path: '#',
      icon: <MdSettings size={16} />
    };

    const sections = [
      {
        title: '遥感影像数据',
        items: [searchSection, bucketSection]
      },
      {
        title: '分析工具',
        items: [toolboxSection, journalSection, labelSection, autoSolverSection]
      },
      {
        title: '个人空间',
        items: [profileSection]
      }
    ];

    return sections;
  }

  const location = useLocation();
  const sections = initSections();
  const [displayCollapser, setDisplayCollapser] = useState(false);
  const theme = useTheme();

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '200px',
        overflowY: 'auto'
      }}
    >
      <Box sx={{ p: 1 }}>
        {sections &&
          sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              {...section}
            />
          ))}
      </Box>
    </Box>
  );

  return (
    <>
      <Drawer
        anchor="left"
        open={openDrawer}
        style={{ zIndex: 1250 }}
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper'
          }
        }}
        variant="temporary"
        sx={{
          display: { xs: 'block', sm: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100vw' }
        }}
      >
        <Box>
          <IconButton
            onClick={() => {
              onOpenDrawerChange(false);
            }}
          >
            {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </Box>
        {content}
      </Drawer>
      <Box display={{ xs: 'none', md: 'block' }}>
        <Drawer
          anchor="left"
          open={openDrawer}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              pt: 8,
              overflow: 'visible'
            }
          }}
          variant="persistent"
          onMouseEnter={() => {
            setDisplayCollapser(true);
          }}
          onMouseLeave={() => {
            setDisplayCollapser(false);
          }}
        >
          {displayCollapser && (
            <Box
              sx={{
                position: 'absolute',
                right: -25,
                top: 100,
                zIndex: 2000,
                backgroundColor: 'background.paper',
                borderColor: `${theme.palette.divider} !important`,
                transform: 'scale(0.7)',
                borderRight: 1,
                borderBottom: 1,
                borderTop: 1,
                borderLeft: 1,
                borderRadius: 50
              }}
            >
              <IconButton
                onClick={() => {
                  onOpenDrawerChange(false);
                }}
              >
                {openDrawer ? <ChevronLeft /> : <ChevronRight />}
              </IconButton>
            </Box>
          )}
          {content}
        </Drawer>
      </Box>
    </>
  );
};

DefaultSidebar.propTypes = {
  openDrawer: PropTypes.bool,
  onOpenDrawerChange: PropTypes.func
};
