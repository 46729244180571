import { Box, Card, CardContent, Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { LoginButton } from '../components';
import { Logo } from 'design';

export const Login = () => {
  return (
    <>
      <Helmet>
        <title>登录 | LabelEarth</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'neutral.900',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minHeight: '100vh',
          pb: 10
        }}
      >
        <Container maxWidth="xs">
          <Box
            sx={{
              mb: 2
            }}
          >
            <Logo />
          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <LoginButton />
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
