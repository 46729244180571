import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Custom hook for handling errors
export const useHandleError = (error) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (error) {
      dispatch({ type: 'SET_ERROR', error: error.error });
    }
  }, [error, dispatch]);
};
