export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK'
};

export const headerHeight = 48;

export const tabHeight = 32;

export const drawerWidth = 240;

export const marginSmall = 12;

// ----------------- map feature related style -----------------------
export const mapLayerSettings = {
  searchCriteriaSpatialExtent: {
    style: {
      weight: 2,
      color: '#fed9a6',
      fillOpacity: 0.2
    },
    highlightStyle: {
      weight: 3,
      color: '#ffc04b',
      fillOpacity: 0.1
    },
    pane: 'searchCriteriaSpatialExtent',
    zIndex: 350
  },
  searchResultSpatialExtent: {
    style: {
      color: '#bdd7e7',
      weight: 2,
      fillOpacity: 0.3, //set no fill to avoid blocking clicks on layers behind
      interactive: false //set this to avoid any blocking events on layers behind
    },
    pane: 'searchResultSpatialExtent',
    zIndex: 400
  },
  searchResultItemsFootprint: {
    style: {
      weight: 1,
      color: '#328ac5',
      fillOpacity: 0.2
    },
    highlightStyle: {
      weight: 3,
      color: '#1f78b4',
      fillOpacity: 0.3
    },
    hoverStyle: {
      weight: 3,
      color: '#1f78b4',
      fillOpacity: 0.3
    },
    pane: 'searchResultItemsFootprint',
    zIndex: 450
  }
};
