import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import 'leaflet/dist/leaflet.css';
import 'nprogress/nprogress.css';
import './map.css';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { AuthProvider } from './authentication';
import { store } from './store';
import { reportWebVitals } from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { SnackbarProvider } from 'notistack';

const root = createRoot(document.getElementById('root'));

root.render(
  <StrictMode>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <BrowserRouter>
              <SnackbarProvider dense maxSnack={3} hideIconVariant>
                <AuthProvider>
                  <App />
                </AuthProvider>
              </SnackbarProvider>
            </BrowserRouter>
          </LocalizationProvider>
        </StyledEngineProvider>
      </ReduxProvider>
    </HelmetProvider>
  </StrictMode>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
