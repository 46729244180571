import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
  EntityState,
  createSelector
} from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'store';
import {
  productTree,
  Product,
  Filter,
  PAGESIZE,
  ZOOM_LEVELS
} from '../utils/constants.ts';
import { formatSearch, getPaginationTokens } from './stacUtils.ts';
import { Utils } from '../services/utils.js';
import type { GeoJSON } from 'geojson';
import type {
  Sortby,
  SearchPayload
  // Bucket,
  // SearchResponse,
  // AggregationResponse,
  // AggregationPayload
} from 'stac-types';
import { searchApi } from './searchApi.ts';
import { favouriteApi } from './favouriteApi.ts';

// ========= <1> manage states for Search Criteria =============
const leafProducts = Utils.filterLeafObjects(productTree).reduce(
  (acc: Record<string, Product>, product: Product) => {
    acc[product.id] = product;
    return acc;
  },
  {} as Record<string, Product>
);

const productAdapter = createEntityAdapter<Product>({
  sortComparer: (a, b) => b.id.localeCompare(a.id)
});

export type SearchCriteria = {
  products: EntityState<Product, string>; //put additional filters under each product
  temporalExtent: {
    start: number | null;
    end: number | null;
    filterMonths: number[];
  };
  spatialExtent: {
    currentTab: 'draw' | 'select' | 'upload';
    storedUIData: {
      draw: {
        features: any[];
        featureClicked?: { id?: string; shape?: string };
      };
      select: { selectedRegions: string[]; hasSubRegions: boolean };
      upload: { fileNames: string[] };
    };
    storedSpatialData: {
      draw: GeoJSON | null;
      select: GeoJSON | null;
      upload: GeoJSON | null;
    };
  };
  commonFilters: {
    cloudCover: Filter;
  };
  hasPerformedSearch?: boolean;
};

const initialSearchCriteriaState: SearchCriteria = {
  products: productAdapter.getInitialState(), //put additional filters under each product
  temporalExtent: { start: null, end: null, filterMonths: [] },
  spatialExtent: {
    currentTab: 'draw',
    storedUIData: {
      draw: { features: [] },
      select: { selectedRegions: [], hasSubRegions: true },
      upload: { fileNames: [] }
    },
    storedSpatialData: {
      draw: null,
      select: null,
      upload: null
    }
  },
  commonFilters: {
    cloudCover: {
      selectedField: 'eo:cloud_cover',
      fieldSchema: {
        title: '云量',
        type: 'number'
      },
      formValues: [0, 100]
    }
  },
  hasPerformedSearch: false
};

export const searchCriteriaSlice = createSlice({
  name: 'searchCriteria',
  initialState: initialSearchCriteriaState,
  reducers: {
    // managing product selection
    toggleProductSelectionByID: (
      state,
      action: PayloadAction<[string, boolean]>
    ) => {
      const [productID, checked] = action.payload;
      const product = leafProducts[productID];

      if (checked) {
        productAdapter.addOne(state.products, product);
      } else {
        productAdapter.removeOne(state.products, productID);
      }
    },

    // managing temporal search criteria
    setTemporalRange: (
      state,
      action: PayloadAction<{ start: number | null; end: number | null }>
    ) => {
      state.temporalExtent.start = action.payload.start;
      state.temporalExtent.end = action.payload.end;
    },
    setTemporalFilterMonths: (state, action: PayloadAction<number[]>) => {
      state.temporalExtent.filterMonths = [...action.payload].sort(
        (a, b) => a - b
      );
    },

    // managing spatial search criteria
    setSpatialExtentTab: (
      state,
      action: PayloadAction<'draw' | 'select' | 'upload'>
    ) => {
      state.spatialExtent.currentTab = action.payload;
    },
    setSpatialExtent: (
      state,
      action: PayloadAction<{
        tab: 'draw' | 'select' | 'upload';
        data: GeoJSON;
      }>
    ) => {
      const { tab, data } = action.payload;
      state.spatialExtent.storedSpatialData[tab] = data;
    },
    addDrawFeature: (
      state,
      action: PayloadAction<{ id: string; shape: string; layer: any }>
    ) => {
      state.spatialExtent.storedUIData.draw.features.push(action.payload);
    },
    updateDrawFeature: (
      state,
      action: PayloadAction<{
        id: string;
        shape: string;
        layer: any;
        [any: string]: any;
      }>
    ) => {
      const prevFeatures = state.spatialExtent.storedUIData.draw.features;
      const prevFeature = prevFeatures.find(
        (feature) => feature.id === action.payload.id
      );
      const updatedFeature = prevFeature
        ? { ...prevFeature, ...action.payload }
        : action.payload;
      state.spatialExtent.storedUIData.draw.features = [
        updatedFeature,
        ...prevFeatures.filter((f) => f.id !== action.payload.id)
      ];
    },
    deleteDrawFeature: (state, action: PayloadAction<string>) => {
      const featureId = action.payload;
      const prevFeatures = state.spatialExtent.storedUIData.draw.features;
      state.spatialExtent.storedUIData.draw.features = [
        ...prevFeatures.filter((f) => f.id !== featureId)
      ];
    },
    drawFeatureClick: (
      state,
      action: PayloadAction<{ id?: string; shape?: string }>
    ) => {
      const { id, shape } = action.payload;
      state.spatialExtent.storedUIData.draw.featureClicked = { id, shape };
    },
    setSelectedRegions: (state, action: PayloadAction<string[]>) => {
      state.spatialExtent.storedUIData.select.selectedRegions = action.payload;
    },
    setHasSubRegions: (state, action: PayloadAction<boolean>) => {
      state.spatialExtent.storedUIData.select.hasSubRegions = action.payload;
    },
    setUploadedFiles: (state, action: PayloadAction<string[]>) => {
      state.spatialExtent.storedUIData.upload.fileNames = action.payload;
    },

    // managing common filters
    setCloudCover: (state, action: PayloadAction<[number, number]>) => {
      state.commonFilters.cloudCover.formValues = action.payload;
    },

    // managing additional filters
    setProductAdditionalFilters: (
      state,
      action: PayloadAction<{
        productID: string;
        additionalFilters: Filter[];
      }>
    ) => {
      const { productID, additionalFilters } = action.payload;
      state.products.entities[productID].additionalFilters = additionalFilters;
    },
    // addProductAdditionalFilter: (
    //   state,
    //   action: PayloadAction<{
    //     productID: string;
    //     additionalFilter: Record<string, any>;
    //   }>
    // ) => {
    //   const { productID, additionalFilter } = action.payload;
    //   if (!state.additionalFilters?.[productID]) {
    //     state.additionalFilters[productID] = [];
    //   }
    //   state.additionalFilters[productID].push(additionalFilter);
    // },
    // updateProductAdditionalFilterByIndex: (
    //   state,
    //   action: PayloadAction<{
    //     productID: string;
    //     filterIndex: number;
    //     newAdditionalFilter: Record<string, any>;
    //   }>
    // ) => {
    //   const { productID, filterIndex, newAdditionalFilter } = action.payload;
    //   const prevFilter = state.additionalFilters[productID][filterIndex];
    //   state.additionalFilters[productID][filterIndex] = {
    //     ...prevFilter,
    //     newAdditionalFilter
    //   };
    // },
    // deleteProductAdditionalFilter: (
    //   state,
    //   action: PayloadAction<{ productID: string; filterIndex: number }>
    // ) => {
    //   const { productID, filterIndex } = action.payload;
    //   const newFilters = state.additionalFilters[productID].filter(
    //     (_, idx) => idx !== filterIndex
    //   );
    //   state.additionalFilters[productID] = newFilters;
    // },

    // managing overall search criteria
    resetSearchCriteria: (state) => {
      // use Object.assign() to copy all properties to trigger store state update
      Object.assign(state, initialSearchCriteriaState);
    },
    loadSearchCriteria: (state, action: PayloadAction<SearchCriteria>) => {
      Object.assign(state, action.payload);
    },
    setHasPerformedSearch: (state, action: PayloadAction<boolean>) => {
      state.hasPerformedSearch = action.payload;
    }
  },
  selectors: {
    // product selection state
    selectSelectedProductIDs: (state) =>
      productAdapter.getSelectors().selectIds(state.products),
    selectSelectedProductLength: (state) =>
      productAdapter.getSelectors().selectIds(state.products).length,
    selectProductIsSelectedByID: (state, productID: string | null) =>
      productID
        ? productAdapter
            .getSelectors()
            .selectById(state.products, productID) !== undefined
        : false,

    // temporal state
    selectTemporalRangeStart: (state) => state.temporalExtent.start,
    selectTemporalRangeEnd: (state) => state.temporalExtent.end,
    selectTemporalFilterMonths: (state) => state.temporalExtent.filterMonths,

    // spatial state
    selectSpatialExtentTab: (state) => state.spatialExtent.currentTab,
    selectCurrentSpatialExtent: (state) =>
      state.spatialExtent.storedSpatialData[state.spatialExtent.currentTab],
    selectDrawState: (state) => state.spatialExtent.storedUIData.draw,
    selectRegionState: (state) => state.spatialExtent.storedUIData.select,
    selectUploadState: (state) => state.spatialExtent.storedUIData.upload,

    // common filter state
    selectCloudCover: (state) => state.commonFilters.cloudCover.formValues,

    // additional filter state
    selectProductAdditionalFilters: (state, productID: string) =>
      state.products.entities[productID]?.additionalFilters,

    // Overall search criteria state
    selectSearchCriteria: (state) => state,
    selectHasPerformedSearch: (state) => state.hasPerformedSearch
  }
});

export const {
  // managing product selection
  toggleProductSelectionByID,

  // managing temporal search criteria
  setTemporalRange,
  setTemporalFilterMonths,

  // managing spatial search criteria
  setSpatialExtentTab,
  setSpatialExtent,
  addDrawFeature,
  updateDrawFeature,
  deleteDrawFeature,
  drawFeatureClick,
  setSelectedRegions,
  setHasSubRegions,
  setUploadedFiles,

  // managing common filters
  setCloudCover,

  // managing additional filters
  setProductAdditionalFilters,

  // managing overall search criteria
  resetSearchCriteria,
  loadSearchCriteria,
  setHasPerformedSearch
} = searchCriteriaSlice.actions;

export const {
  // product selection state
  selectSelectedProductIDs,
  selectSelectedProductLength,
  selectProductIsSelectedByID,

  // temporal state
  selectTemporalRangeStart,
  selectTemporalRangeEnd,
  selectTemporalFilterMonths,

  // spatial state
  selectSpatialExtentTab,
  selectCurrentSpatialExtent,
  selectDrawState,
  selectRegionState,
  selectUploadState,

  // common filter state
  selectCloudCover,

  // additional filter state,
  selectProductAdditionalFilters,

  // Overall search criteria state
  selectSearchCriteria,
  selectHasPerformedSearch
} = searchCriteriaSlice.selectors;

// ======================= <2> manage states for Search Control ====================================
export type SearchControl = {
  pageSize: number;
  currentPage: number;
  pageToken?: string;
  sortby?: Sortby[];
};

const initialSearchControlState: SearchControl = {
  pageSize: PAGESIZE,
  currentPage: 1,
  pageToken: null,
  sortby: null
  //[{ field: 'datetime', direction: 'desc' }]
};

export const searchControlSlice = createSlice({
  name: 'searchControl',
  initialState: initialSearchControlState,
  reducers: {
    resetSearchControl: (state, action: PayloadAction<void>) => {
      // use Object.assign() to copy all properties to trigger store state update
      Object.assign(state, initialSearchControlState);
    },
    setCurrentPage: (
      state,
      action: PayloadAction<{
        changeCurrentPageBy?: number;
        currentPage?: number;
      }>
    ) => {
      const { changeCurrentPageBy, currentPage } = action.payload;
      if (changeCurrentPageBy) {
        state.currentPage += changeCurrentPageBy;
      }
      if (currentPage) {
        state.currentPage = currentPage;
      }
    },
    setPageToken: (state, action: PayloadAction<string>) => {
      state.pageToken = action.payload;
    },
    setSortby: (state, action: PayloadAction<Sortby[]>) => {
      state.sortby = action.payload;
    }
  },
  selectors: {
    selectSearchControl: (state) => state,
    selectPageSize: (state) => state.pageSize,
    selectCurrentPage: (state) => state.currentPage,
    selectSortby: (state) => state.sortby
  }
});

export const { resetSearchControl, setPageToken, setCurrentPage, setSortby } =
  searchControlSlice.actions;

export const {
  selectSearchControl,
  selectCurrentPage,
  selectPageSize,
  selectSortby
} = searchControlSlice.selectors;

// ============ <3> manage states for Search Results ====================================
// save the search querying parameters in store state
// and create selectors with enpoint's `select` property to acquire cached query results
export type SearchResult = {
  searchId: number;
  searchResultQueryParameters: SearchPayload; //the query parameters for the entire search, depending on SearchCriteria
  searchPageQueryParameters: SearchPayload; //the query parameters for the current page, depending on SearchCriteria + SearchControl
  isSearching: boolean;
};

const initialSearchResultState: SearchResult = {
  searchId: null,
  searchResultQueryParameters: null,
  searchPageQueryParameters: null,
  isSearching: false
};

export const searchResultSlice = createSlice({
  name: 'searchResult',
  initialState: initialSearchResultState,
  reducers: {
    setSearchId: (state, action: PayloadAction<number>) => {
      state.searchId = action.payload;
    },
    setSearchResultQueryParameters: (
      state,
      action: PayloadAction<SearchPayload>
    ) => {
      state.searchResultQueryParameters = action.payload;
    },
    setSearchPageQueryParameters: (
      state,
      action: PayloadAction<SearchPayload>
    ) => {
      state.searchPageQueryParameters = action.payload;
    },
    setIsSearching: (state, action: PayloadAction<boolean>) => {
      state.isSearching = action.payload;
    },
    clearSearchResults: (state, action: PayloadAction<void>) => {
      // use Object.assign() to copy all properties to trigger store state update
      Object.assign(state, initialSearchResultState);
    }
  },
  extraReducers(builder) {
    builder.addMatcher(
      searchApi.endpoints.searchItems.matchPending,
      (state, action) => {
        state.isSearching = true;
      }
    );
    builder.addMatcher(
      searchApi.endpoints.searchItems.matchFulfilled,
      (state, action) => {
        state.isSearching = false;
      }
    );
    builder.addMatcher(
      searchApi.endpoints.searchItems.matchRejected,
      (state, action) => {
        state.isSearching = false;
      }
    );
  },
  selectors: {
    selectSearchId: (state) => state.searchId,
    selectSearchResultQueryParameters: (state) =>
      state.searchResultQueryParameters,
    selectSearchPageQueryParameters: (state) => state.searchPageQueryParameters,
    selectIsSearching: (state) => state.isSearching
  }
});

export const {
  clearSearchResults,
  setSearchId,
  setSearchResultQueryParameters,
  setSearchPageQueryParameters,
  setIsSearching
} = searchResultSlice.actions;

export const {
  selectSearchId,
  selectSearchResultQueryParameters,
  selectSearchPageQueryParameters,
  selectIsSearching
} = searchResultSlice.selectors;

// create selectors to acquire search results
// depend on query parameters for the current page, in SearchCriteria + SearchControl
export const selectSearchResultPage = createSelector(
  (state: RootState) => state[searchApi.reducerPath], //select just api state to avoid unnecessary re-render
  selectSearchPageQueryParameters,

  (state, searchQueryParameters) =>
    searchApi.endpoints.searchItems.select({ searchQueryParameters })({
      [searchApi.reducerPath]: state
    })
);

// depend on query parameters for the entire search, only in SearchCriteria
export const selectSearchResultSpatialExtent = createSelector(
  (state: RootState) => state[searchApi.reducerPath],
  selectSearchResultQueryParameters,

  (state, searchQueryParameters) =>
    searchApi.endpoints.getSearchResultSpatialExtent.select({
      searchQueryParameters
    })({ [searchApi.reducerPath]: state })
);

export const selectSearchResultTemporalFrequency = createSelector(
  (state: RootState) => state[searchApi.reducerPath],
  selectSearchResultQueryParameters,

  (state, searchQueryParameters) =>
    searchApi.endpoints.getSearchResultTemporalFrequency.select({
      searchQueryParameters,
      zoomLevel: ZOOM_LEVELS.month
    })({ [searchApi.reducerPath]: state })
);

export const selectSearchResultNumberMatched = createSelector(
  selectSearchResultPage,
  (searchResultPage) => searchResultPage?.data?.context?.matched ?? null
);
export const selectSearchResultPageTokens = createSelector(
  selectSearchResultPage,
  (searchResultPage) =>
    searchResultPage?.data && getPaginationTokens(searchResultPage.data)
);

// =================== <4> manage states for result display ================
export type ResultDisplay = {
  selectedItemIDs: string[];
  highlightedItemIDs: string[];
  temporalZoomLevel: number;
};

const initialResultDisplayState: ResultDisplay = {
  selectedItemIDs: [],
  highlightedItemIDs: [],
  temporalZoomLevel: ZOOM_LEVELS.month
};

export const resultDisplaySlice = createSlice({
  name: 'resultDisplay',
  initialState: initialResultDisplayState,
  reducers: {
    // manage selection state
    toggleItemSelectByID: (state, action: PayloadAction<[string, boolean]>) => {
      console.log('item toggled', action.payload);
      const [itemID, checked] = action.payload;

      if (!itemID) return;

      if (checked) {
        !state.selectedItemIDs.includes(itemID) &&
          state.selectedItemIDs.push(itemID);
      } else {
        state.selectedItemIDs = state.selectedItemIDs.filter(
          (id) => id !== itemID
        );
      }
    },
    clearSelectedItems: (state) => {
      state.selectedItemIDs = [];
    },
    setSelectedItemIDs: (state, action: PayloadAction<string[]>) => {
      state.selectedItemIDs = action.payload;
    },

    // manage hightlighted items state
    setHighlightedItemIDs: (state, action: PayloadAction<string[]>) => {
      state.highlightedItemIDs = action.payload;
    },

    // manage temporal zoom level
    setTemporalZoomLevel: (state, action: PayloadAction<number>) => {
      state.temporalZoomLevel = action.payload;
    },

    // manage overall result display state
    resetResultDisplay: (state) => {
      Object.assign(state, initialResultDisplayState);
    }
  },
  selectors: {
    // selection state
    selectSelectedItemIDs: (state) => state.selectedItemIDs,
    selectSelectedItemsLength: (state) => state.selectedItemIDs.length,
    selectItemIsSelectedByID: (state, itemID: string | null) =>
      itemID ? state.selectedItemIDs.includes(itemID) : false,

    // hightlighted items
    selectHighlightedItemIDs: (state) => state.highlightedItemIDs,
    selectHighlightedItemsLength: (state) => state.highlightedItemIDs.length,
    selectItemIsHighlightedByID: (state, itemID: string | null) =>
      itemID ? state.highlightedItemIDs.includes(itemID) : false,

    // temporal zoom level state
    selectTemporalZoomLevel: (state) => state.temporalZoomLevel
  }
});

export const {
  // manage selection state
  toggleItemSelectByID,
  clearSelectedItems,
  setSelectedItemIDs,

  // manage hightlighted items state
  setHighlightedItemIDs,

  // manage temporal zoom level
  setTemporalZoomLevel,

  // manage overall result display state
  resetResultDisplay
} = resultDisplaySlice.actions;

export const {
  // selection state
  selectSelectedItemIDs,
  selectSelectedItemsLength,
  selectItemIsSelectedByID,

  // hightlighted items
  selectHighlightedItemIDs,
  selectHighlightedItemsLength,
  selectItemIsHighlightedByID,

  // temporal zoom level state
  selectTemporalZoomLevel
} = resultDisplaySlice.selectors;

// ================ <5> wire up states with thunks =======================
// search trigger
export const triggerNewSearch = (): AppThunk => async (dispatch, getState) => {
  const searchCriteria = selectSearchCriteria(getState());
  const searchQueryParameters = formatSearch({
    searchCriteria,
    searchControl: initialSearchControlState
  });

  // 1. search items
  dispatch(searchApi.endpoints.searchItems.initiate({ searchQueryParameters }))
    .unwrap()
    .then(() => {
      // 3. update the search query parameters in store state on search success,
      //    so that components can access results through selectors with these states
      dispatch(setSearchResultQueryParameters(searchQueryParameters));
      dispatch(setSearchPageQueryParameters(searchQueryParameters));

      // 4. set hasPerformedSearch to true
      dispatch(setHasPerformedSearch(true));

      // 5. reset search control
      dispatch(resetSearchControl());

      // 6. reset result display state
      dispatch(resetResultDisplay());
    })
    .catch((error) => {
      console.error('Error during search:', error);
    });

  // 2. update info for the entire search criteria
  dispatch(
    favouriteApi.endpoints.addSearchLog.initiate({
      searchCriteria
    })
  );
  dispatch(
    searchApi.endpoints.getSearchResultSpatialExtent.initiate({
      searchQueryParameters
    })
  );
  dispatch(
    searchApi.endpoints.getSearchResultTemporalFrequency.initiate({
      searchQueryParameters,
      zoomLevel: ZOOM_LEVELS.month
    })
  );
};

// reset search
export const resetSearch = (): AppThunk => async (dispatch, getState) => {
  // 1. reset search criteria AND searh control
  dispatch(resetSearchCriteria());
  dispatch(resetSearchControl());

  // 2. clear the search results
  dispatch(clearSearchResults());

  // 3. reset result display state
  dispatch(resetResultDisplay());
};

// searh control modifiers
export const changePage =
  (pageChangeInfo: {
    changeCurrentPageBy: number;
    pageToken?: string;
  }): AppThunk =>
  async (dispatch, getState) => {
    const { changeCurrentPageBy, pageToken } = pageChangeInfo;
    // 1. generate new search params with updated search control
    const searchCriteria = selectSearchCriteria(getState());
    const searchControl = selectSearchControl(getState());
    const updatedSearchControl = {
      ...searchControl,
      pageToken: pageToken
    };
    const searchQueryParameters = formatSearch({
      searchCriteria,
      searchControl: updatedSearchControl
    });

    // 2. search items
    dispatch(
      searchApi.endpoints.searchItems.initiate({ searchQueryParameters })
    )
      .unwrap()
      .then((res) => {
        // 3. when query fullfilled, save the updated search query parameters as params
        //    so that components would use to acquire returned data through selectors
        dispatch(setSearchPageQueryParameters(searchQueryParameters));

        // 4. then update the search control state
        dispatch(
          setCurrentPage({
            changeCurrentPageBy
          })
        );

        // 5. clear highlighted items
        dispatch(setHighlightedItemIDs([]));
      })
      .catch((error) => {
        console.error('Error during search:', error);
      });

    // 6. NO NEED to update info for the entire search criteria
  };

export const changeSortby =
  (sortby: Sortby[]): AppThunk =>
  async (dispatch, getState) => {
    // 1. generate new search params with updated search control
    const searchCriteria = selectSearchCriteria(getState());
    const searchControl = selectSearchControl(getState());
    const updatedSearchControl = {
      ...searchControl,
      sortby: sortby,
      currentPage: 1, // reset to the first page
      pageToken: null // reset to the first page
    };
    const searchQueryParameters = formatSearch({
      searchCriteria,
      searchControl: updatedSearchControl
    });

    // 2. search items
    dispatch(
      searchApi.endpoints.searchItems.initiate({ searchQueryParameters })
    )
      .unwrap()
      .then(() => {
        // 3. when query fullfilled, save the updated search query parameters as params
        //    so that components would use to acquire returned data through selectors
        dispatch(setSearchPageQueryParameters(searchQueryParameters));

        // 4. then update the search control state
        dispatch(setSortby(sortby));
        dispatch(setCurrentPage({ currentPage: 1 })); // reset to the first page
        dispatch(setPageToken(null)); // reset to the first page

        // 5. clear highlighted items
        dispatch(setHighlightedItemIDs([]));
      })
      .catch((error) => {
        console.error('Error during search:', error);
      });

    // 6. NO NEED to update info for the entire search criteria
  };

// load favourite
export const loadFavourite =
  (favoriteID: number): AppThunk =>
  async (dispatch, getState) => {
    // first clear all states
    dispatch(resetSearch());

    try {
      // 0. recover search criteria from favourite and sync with UI
      const { favoritesResult } = await dispatch(
        favouriteApi.endpoints.getFavouriteCollectionInfoById.initiate({
          favoriteID
        })
      ).unwrap();
      const searchCriteria = favoritesResult.form_json;
      dispatch(loadSearchCriteria(searchCriteria)); //sync with UI

      // 1. search items
      const searchQueryParameters = formatSearch({
        searchCriteria,
        searchControl: initialSearchControlState
      });
      dispatch(
        searchApi.endpoints.searchItems.initiate({ searchQueryParameters })
      )
        .unwrap()
        .then(() => {
          // 3. update the search query parameters in store state on search success,
          //    so that components can access results through selectors with these states
          dispatch(setSearchResultQueryParameters(searchQueryParameters));
          dispatch(setSearchPageQueryParameters(searchQueryParameters));

          // 4. set hasPerformedSearch to true
          dispatch(setHasPerformedSearch(true));

          // 5. reset search control
          dispatch(resetSearchControl());

          // 6. no need to reset result display state
        })
        .catch((error) => {
          console.error('Error during search:', error);
        });

      // 2. update info for the entire search criteria
      // no need to add search log
      dispatch(
        searchApi.endpoints.getSearchResultSpatialExtent.initiate({
          searchQueryParameters
        })
      );
      dispatch(
        searchApi.endpoints.getSearchResultTemporalFrequency.initiate({
          searchQueryParameters,
          zoomLevel: ZOOM_LEVELS.month
        })
      );
    } catch (error) {
      console.error('Error during search:', error);
    }

    // recover item selection state from the favourite collection
    try {
      const { ids } = await dispatch(
        favouriteApi.endpoints.getFavouriteItems.initiate({
          favoriteID
        })
      ).unwrap();
      const selectedItemIDs = ids;
      dispatch(setSelectedItemIDs(selectedItemIDs));
    } catch (error) {
      console.error('Error during search:', error);
    }
  };
