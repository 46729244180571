import React from 'react';
import { AppBar, Box, IconButton, Toolbar, Link, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Menu } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { AccountPopover } from '../popovers';
import { Link as RouterLink } from 'react-router-dom';
import { Logo } from '../Logo';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.primary.main
  }
}));

export const DefaultNavbar = ({ openDrawer, onOpenDrawerChange }) => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar variant="dense">
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => {
            onOpenDrawerChange(!openDrawer);
          }}
        >
          <Menu />
        </IconButton>
        <Box display={{ xs: 'block', lg: 'block', xl: 'block' }} sx={{ mx: 2 }}>
          <Logo />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            mx: 2
          }}
        >
          <Stack direction="row" spacing={2}>
            <Link
              to="/search"
              color="white"
              underline="none"
              component={RouterLink}
            >
              影像检索
            </Link>
            <Link
              to="/favorite"
              color="white"
              underline="none"
              component={RouterLink}
            >
              我的收藏
            </Link>
          </Stack>
        </Box>
        <Box>
          <AccountPopover />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export const EmptyNavbar = () => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar variant="dense">
        <Box>
          <Logo />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            mx: 2
          }}
        >
          <Stack direction="row" spacing={2}>
            <Link
              to="/search"
              color="white"
              underline="none"
              component={RouterLink}
            >
              影像检索
            </Link>
            <Link
              to="/favorite"
              color="white"
              underline="none"
              component={RouterLink}
            >
              我的收藏
            </Link>
          </Stack>
        </Box>
        <Box>
          <AccountPopover />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

DefaultNavbar.propTypes = {
  openDrawer: PropTypes.bool,
  onOpenDrawerChange: PropTypes.func
};
