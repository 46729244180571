import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from 'react-redux';
import { configureStore, Action, ThunkAction } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { errorReducer } from '../globalErrors/errorReducer';
import authReducer from '../authentication/authSlice';
import { authApi } from '../authentication/authApi.ts';
import {
  searchCriteriaSlice,
  searchControlSlice,
  searchResultSlice,
  resultDisplaySlice
} from '../modules/EODAG/services/searchSlice.ts';
import { searchApi } from '../modules/EODAG/services/searchApi.ts';
import { favouriteApi } from '../modules/EODAG/services/favouriteApi.ts';
import { eodagApi } from '../modules/EODAG/services/eodagApi.ts';
import { pgStacApi } from '../modules/PGSTAC/services/pgStacApi.ts';
import { titilerApi } from '../modules/EODAG/services/titilerApi.ts';
import { githubApi } from '../modules/GeoJournal/services/githubApi.js';
import { regionApi } from '../modules/EODAG/services/regionApi.js';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    error: errorReducer,

    [searchCriteriaSlice.name]: searchCriteriaSlice.reducer,
    [searchControlSlice.name]: searchControlSlice.reducer,
    [searchResultSlice.name]: searchResultSlice.reducer,
    [resultDisplaySlice.name]: resultDisplaySlice.reducer,

    [authApi.reducerPath]: authApi.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
    [favouriteApi.reducerPath]: favouriteApi.reducer,
    [eodagApi.reducerPath]: eodagApi.reducer,
    [pgStacApi.reducerPath]: pgStacApi.reducer,
    [titilerApi.reducerPath]: titilerApi.reducer,
    [githubApi.reducerPath]: githubApi.reducer,
    [regionApi.reducerPath]: regionApi.reducer
  },
  devTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === 'true',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(authApi.middleware)
      .concat(favouriteApi.middleware)
      .concat(searchApi.middleware)
      .concat(titilerApi.middleware)
      .concat(regionApi.middleware)
  // .concat(githubApi.middleware)
  // .concat(eodagApi.middleware)
  // .concat(pgStacApi.middleware)
});

setupListeners(store.dispatch);

// export some ts types
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;

// export typed hooks for easy use in project
export const useSelector = useReduxSelector.withTypes<RootState>();
export const useDispatch = useReduxDispatch.withTypes<AppDispatch>();
