export const propertyFields = [
  {
    title: '基本信息',
    value: {
      gsd: {
        label: '空间分辨率 (m)'
      },
      datetime: {
        label: '影像采集时间'
      },
      start_datetime: {
        label: '影像采集起始时间'
      },
      end_datetime: {
        label: '影像采集结束时间'
      },
      'eo:cloud_cover': {
        label: '云量 (%)'
      }
    }
  },
  {
    title: 'Landsat ARD 扩展信息',
    value: {
      'landsat:fill': {
        label: '填充像素百分比 (%)'
      },
      'landsat:grid_region': {
        label: 'U.S. ARD 区域',
        fn: (value) => {
          const valueMapping = {
            CU: '美国本土',
            AK: '阿拉斯加',
            HI: '夏威夷'
          };
          return `${value} (${valueMapping[value]})`;
        }
      },
      'landsat:scene_count': {
        label: '网格内原始影像数'
      },
      'landsat:snow_ice_cover': {
        label: '冰雪覆盖率（有效像素）(%)'
      },
      'landsat:cloud_shadow_cover': {
        label: '云层阴影覆盖率（有效像素）(%)'
      },
      'landsat:grid_vertical': {
        label: 'U.S. ARD 网格纵坐标号'
      },
      'landsat:grid_horizontal': {
        label: 'U.S. ARD 网格横坐标号'
      }
    }
  }
];

export const productTree: Product[] = [
  {
    type: 'productCategory',
    id: 'sensorType:OPTICAL',
    label: 'OPT',
    tooltip: '光学影像',
    children: [
      {
        type: 'productCategory',
        id: 'platform:LANDSAT_4_5:instruments:TM',
        label: 'Landsat 4-5 | TM',
        tooltip: 'Landsat 4-5 TM（专题制图仪）',
        children: [
          {
            type: 'product',
            id: 'product:LANDSAT_4_5_C2L1',
            label: 'Landsat 4-5 TM C2 L1',
            tooltip: 'Landsat 4-5 Level-1 云顶反照率影像',
            applyCloudCover: true,
            info: {
              doi: 'https://doi.org/10.5066/P918ROHC'
            },
            filter: {
              op: 'and',
              args: [
                {
                  op: 'in',
                  args: [{ property: 'platform' }, ['LANDSAT_4', 'LANDSAT_5']]
                },
                {
                  op: '=',
                  args: [{ property: 'product:type' }, 'landsat-c2l1']
                }
              ]
            }
          },
          {
            type: 'product',
            id: 'product:LANDSAT_4_5_C2L2_SR',
            label: 'Landsat 4-5 TM C2 L2 - Surface Reflectance',
            tooltip: 'Landsat 4-5 Level-2 地表反照率影像产品',
            applyCloudCover: true,
            info: {
              doi: 'https://doi.org/10.5066/P9IAXOVV'
            },
            filter: {
              op: 'and',
              args: [
                {
                  op: 'in',
                  args: [{ property: 'platform' }, ['LANDSAT_4', 'LANDSAT_5']]
                },
                {
                  op: '=',
                  args: [{ property: 'product:type' }, 'landsat-c2l2-sr']
                }
              ]
            }
          },
          {
            type: 'product',
            id: 'product:LANDSAT_4_5_C2L2_ST',
            label: 'Landsat 4-5 TM C2 L2 - Surface Temperature',
            tooltip: 'Landsat 4-5 Level-2 地表温度影像产品',
            applyCloudCover: true,
            info: {
              doi: 'https://doi.org/10.5066/P9IAXOVV'
            },
            filter: {
              op: 'and',
              args: [
                {
                  op: 'in',
                  args: [{ property: 'platform' }, ['LANDSAT_4', 'LANDSAT_5']]
                },
                {
                  op: '=',
                  args: [{ property: 'product:type' }, 'landsat-c2l2-st']
                }
              ]
            }
          },
          {
            type: 'product',
            id: 'product:LANDSAT_4_5_C2ARD_SR',
            label: 'Landsat 4-5 C2 U.S. ARD - Surface Reflectance',
            tooltip: 'Landsat 4-5 U.S. ARD 地表反照率影像产品',
            applyCloudCover: true,
            info: {
              doi: 'https://doi.org/10.5066/P960F8OC',
              additionalFilterSchema: 'landsat-c2ard-schema'
            },
            filter: {
              op: 'and',
              args: [
                {
                  op: 'in',
                  args: [{ property: 'platform' }, ['LANDSAT_4', 'LANDSAT_5']]
                },
                {
                  op: '=',
                  args: [{ property: 'product:type' }, 'landsat-c2ard-sr']
                }
              ]
            }
          },
          {
            type: 'product',
            id: 'product:LANDSAT_4_5_C2ARD_ST',
            label: 'Landsat 4-5 C2 U.S. ARD - Surface Temperature',
            tooltip: 'Landsat 4-5 U.S. ARD 地表温度影像产品',
            applyCloudCover: true,
            info: {
              doi: 'https://doi.org/10.5066/P960F8OC',
              additionalFilterSchema: 'landsat-c2ard-schema'
            },
            filter: {
              op: 'and',
              args: [
                {
                  op: 'in',
                  args: [{ property: 'platform' }, ['LANDSAT_4', 'LANDSAT_5']]
                },
                {
                  op: '=',
                  args: [{ property: 'product:type' }, 'landsat-c2ard-st']
                }
              ]
            }
          }
        ]
      },
      {
        type: 'productCategory',
        id: 'platform:LANDSAT_7:instruments:ETM',
        label: 'Landsat 7 | ETM+',
        tooltip: 'Landsat 7 ETM+（增强专题制图仪）',
        children: [
          {
            type: 'product',
            id: 'product:LANDSAT_7_C2L1',
            label: 'Landsat 7 ETM+ C2 L1',
            tooltip: 'Landsat 7 Level-1 云顶反照率影像',
            applyCloudCover: true,
            info: {
              doi: 'https://doi.org/10.5066/P9TU80IG'
            },
            filter: {
              op: 'and',
              args: [
                {
                  op: '=',
                  args: [{ property: 'platform' }, 'LANDSAT_7']
                },
                {
                  op: '=',
                  args: [{ property: 'product:type' }, 'landsat-c2l1']
                }
              ]
            }
          },
          {
            type: 'product',
            id: 'product:LANDSAT_7_C2L2_SR',
            label: 'Landsat 7 ETM+ C2 L2 - Surface Reflectance',
            tooltip: 'Landsat 7 Level-2 地表反照率影像产品',
            applyCloudCover: true,
            info: {
              doi: 'https://doi.org/10.5066/P9C7I13B'
            },
            filter: {
              op: 'and',
              args: [
                {
                  op: '=',
                  args: [{ property: 'platform' }, 'LANDSAT_7']
                },
                {
                  op: '=',
                  args: [{ property: 'product:type' }, 'landsat-c2l2-sr']
                }
              ]
            }
          },
          {
            type: 'product',
            id: 'product:LANDSAT_7_C2L2_ST',
            label: 'Landsat 7 ETM+ C2 L2 Surface Temperature',
            tooltip: 'Landsat 7 Level-2 地表温度影像产品',
            applyCloudCover: true,
            info: {
              doi: 'https://doi.org/10.5066/P9C7I13B'
            },
            filter: {
              op: 'and',
              args: [
                {
                  op: '=',
                  args: [{ property: 'platform' }, 'LANDSAT_7']
                },
                {
                  op: '=',
                  args: [{ property: 'product:type' }, 'landsat-c2l2-st']
                }
              ]
            }
          },
          {
            type: 'product',
            id: 'product:LANDSAT_7_C2ARD_SR',
            label: 'Landsat 7 C2 U.S. ARD - Surface Reflectance',
            tooltip: 'Landsat 7 U.S. ARD 地表反照率影像产品',
            applyCloudCover: true,
            info: {
              doi: 'https://doi.org/10.5066/P960F8OC',
              additionalFilterSchema: 'landsat-c2ard-schema'
            },
            filter: {
              op: 'and',
              args: [
                {
                  op: '=',
                  args: [{ property: 'platform' }, 'LANDSAT_7']
                },
                {
                  op: '=',
                  args: [{ property: 'product:type' }, 'landsat-c2ard-sr']
                }
              ]
            }
          },
          {
            type: 'product',
            id: 'product:LANDSAT_7_C2ARD_ST',
            label: 'Landsat 7 C2 U.S. ARD - Surface Temperature',
            tooltip: 'Landsat 7 U.S. ARD 地表温度影像产品',
            applyCloudCover: true,
            info: {
              doi: 'https://doi.org/10.5066/P960F8OC',
              additionalFilterSchema: 'landsat-c2ard-schema'
            },
            filter: {
              op: 'and',
              args: [
                {
                  op: '=',
                  args: [{ property: 'platform' }, 'LANDSAT_7']
                },
                {
                  op: '=',
                  args: [{ property: 'product:type' }, 'landsat-c2ard-st']
                }
              ]
            }
          }
        ]
      },
      {
        type: 'productCategory',
        id: 'platform:LANDSAT_8_9:instruments:OLI_TIRS',
        label: 'Landsat 8-9 | OLI/TIRS',
        tooltip: 'Landsat 8-9 OLI（陆地成像仪）/TIRS（热红外传感器）',
        children: [
          {
            type: 'product',
            id: 'product:LANDSAT_8_9_C2L1',
            label: 'Landsat 8-9 OLI/TIRS C2 L1',
            tooltip: 'Landsat 8-9 Level-1 云顶反照率影像',
            applyCloudCover: true,
            info: {
              doi: 'https://doi.org/10.5066/P975CC9B'
            },
            filter: {
              op: 'and',
              args: [
                {
                  op: 'in',
                  args: [{ property: 'platform' }, ['LANDSAT_8', 'LANDSAT_9']]
                },
                {
                  op: '=',
                  args: [{ property: 'product:type' }, 'landsat-c2l1']
                }
              ]
            }
          },
          {
            type: 'product',
            id: 'product:LANDSAT_8_9_C2L2_SR',
            label: 'Landsat 8-9 OLI/TIRS C2 L2 - Surface Reflectance',
            tooltip: 'Landsat 8-9 Level-2 地表反照率影像产品',
            applyCloudCover: true,
            info: {
              doi: 'https://doi.org/10.5066/P9OGBGM6'
            },
            filter: {
              op: 'and',
              args: [
                {
                  op: 'in',
                  args: [{ property: 'platform' }, ['LANDSAT_8', 'LANDSAT_9']]
                },
                {
                  op: '=',
                  args: [{ property: 'product:type' }, 'landsat-c2l2-sr']
                }
              ]
            }
          },
          {
            type: 'product',
            id: 'product:LANDSAT_8_9_C2L2_ST',
            label: 'Landsat 8-9 OLI/TIRS C2 L2 - Surface Temperature',
            tooltip: 'Landsat 8-9 Level-2 地表温度影像产品',
            applyCloudCover: true,
            info: {
              doi: 'https://doi.org/10.5066/P9OGBGM6'
            },
            filter: {
              op: 'and',
              args: [
                {
                  op: 'in',
                  args: [{ property: 'platform' }, ['LANDSAT_8', 'LANDSAT_9']]
                },
                {
                  op: '=',
                  args: [{ property: 'product:type' }, 'landsat-c2l2-st']
                }
              ]
            }
          },
          {
            type: 'product',
            id: 'product:LANDSAT_8_9_C2ARD_SR',
            label: 'Landsat 8-9 C2 U.S. ARD - Surface Reflectance',
            tooltip: 'Landsat 8-9 U.S. ARD 地表反照率影像产品',
            applyCloudCover: true,
            info: {
              doi: 'https://doi.org/10.5066/P960F8OC',
              additionalFilterSchema: 'landsat-c2ard-schema'
            },
            filter: {
              op: 'and',
              args: [
                {
                  op: 'in',
                  args: [{ property: 'platform' }, ['LANDSAT_8', 'LANDSAT_9']]
                },
                {
                  op: '=',
                  args: [{ property: 'product:type' }, 'landsat-c2ard-sr']
                }
              ]
            }
          },
          {
            type: 'product',
            id: 'product:LANDSAT_8_9_C2ARD_ST',
            label: 'Landsat 8-9 C2 U.S. ARD - Surface Temperature',
            tooltip: 'Landsat 8-9 U.S. ARD 地表温度影像产品',
            applyCloudCover: true,
            info: {
              doi: 'https://doi.org/10.5066/P960F8OC',
              additionalFilterSchema: 'landsat-c2ard-schema'
            },
            filter: {
              op: 'and',
              args: [
                {
                  op: 'in',
                  args: [{ property: 'platform' }, ['LANDSAT_8', 'LANDSAT_9']]
                },
                {
                  op: '=',
                  args: [{ property: 'product:type' }, 'landsat-c2ard-sr']
                }
              ]
            }
          }
        ]
      },
      {
        type: 'productCategory',
        id: 'platform:SENTINEL_2:instruments:MSI',
        label: 'Sentinel-2 | MSI',
        tooltip: 'Sentinel-2（哨兵2号）MSI（多光谱成像仪）',
        children: [
          {
            type: 'product',
            id: 'product:S2MSI1C',
            label: 'Sentinel-2 MSI Level-1C',
            tooltip: 'Sentinel-2 Level-1C 云顶反照率影像',
            applyCloudCover: true,
            info: {
              doi: 'https://sentiwiki.copernicus.eu/web/s2-products#S2Products-L1CS2-Products-L1Ctrue'
            },
            filter: {
              op: '=',
              args: [{ property: 'product:type' }, 'S2MSI1C']
            }
          },
          {
            type: 'product',
            id: 'product:S2MSI2A',
            label: 'Sentinel-2 MSI Level-2A',
            tooltip: 'Sentinel-2 Level-2A 地表反照率影像产品',
            applyCloudCover: true,
            info: {
              doi: 'https://sentiwiki.copernicus.eu/web/s2-products#S2Products-L2AS2-Products-L2Atrue'
            },
            filter: {
              op: '=',
              args: [{ property: 'product:type' }, 'S2MSI2A']
            }
          }
        ]
      },
      {
        type: 'productCategory',
        id: 'platform:SENTINEL_3:instruments:OLCI',
        label: 'Sentinel-3 | OLCI',
        tooltip: '哨兵3号 海洋与陆地多光谱成像仪（OLCI）',
        children: [
          {
            type: 'product',
            id: 'product:OL_1_EFR',
            label: 'Sentinel-3 OLCI Level-1B Full Resolution',
            tooltip: '哨兵3号 OLCI Level-1B 300m分辨率影像'
          },
          {
            type: 'product',
            id: 'product:OL_1_ERR',
            label: 'Sentinel-3 OLCI Level-1B Reduced Resolution',
            tooltip: '哨兵3号 OLCI Level-1B 1200m分辨率影像'
          },
          {
            type: 'product',
            id: 'product:OL_2_LFR',
            label: 'Sentinel-3 OLCI Level-2 Land Full Resolution',
            tooltip: '哨兵3号 OLCI Level-2 300m分辨率陆地影像产品'
          },
          {
            type: 'product',
            id: 'product:OL_2_LRR',
            label: 'Sentinel-3 OLCI Level-2 Land Reduced Resolution',
            tooltip: '哨兵3号 OLCI Level-2 1200m分辨率陆地影像产品'
          },
          {
            type: 'product',
            id: 'product:OL_2_WFR',
            label: 'Sentinel-3 OLCI Level-2 Water Full Resolution',
            tooltip: '哨兵3号 OLCI Level-2 300m分辨率水体影像产品'
          },
          {
            type: 'product',
            id: 'product:OL_2_WRR',
            label: 'Sentinel-3 OLCI Level-2 Water Reduced Resolution',
            tooltip: '哨兵3号 OLCI Level-2 1200m分辨率水体影像产品'
          }
        ]
      },
      {
        type: 'productCategory',
        id: 'platform:TERRA:instruments:ASTER',
        label: 'Terra | ASTER',
        tooltip: 'Terra卫星 ASTER（先进天基热辐射和反射成像仪）',
        children: [
          {
            type: 'product',
            id: 'product:AST_L1A',
            label: 'ASTER Level-1A Reconstructed Unprocessed Instrument Data',
            info: {
              doi: 'https://doi.org/10.5067/ASTER/AST_L1A.003'
            },
            filter: {
              op: '=',
              args: [{ property: 'product:type' }, 'AST_L1A']
            }
          },
          {
            type: 'product',
            id: 'product:AST_L1B',
            label: 'ASTER Level-1B Registered Radiance an the Sensor',
            info: {
              doi: 'https://doi.org/10.5067/ASTER/AST_L1B.003'
            },
            filter: {
              op: '=',
              args: [{ property: 'product:type' }, 'AST_L1B']
            }
          },
          {
            type: 'product',
            id: 'product:AST_L1T',
            label:
              'ASTER Level-1T Registered Radiance at the Sensor (Precision Terrain Corrected)',
            info: {
              doi: 'https://doi.org/10.5067/ASTER/AST_L1T.003'
            },
            filter: {
              op: '=',
              args: [{ property: 'product:type' }, 'AST_L1T']
            }
          },
          {
            type: 'product',
            id: 'product:AST_05',
            label: 'ASTER Level-2 Surface Emissivity',
            info: {
              doi: 'https://doi.org/10.5067/ASTER/AST_05.003'
            },
            filter: {
              op: '=',
              args: [{ property: 'product:type' }, 'AST_05']
            }
          },
          {
            type: 'product',
            id: 'product:AST_07',
            label: 'ASTER Level-2 Surface Reflectance (VNIR & SWIR)',
            info: {
              doi: 'https://doi.org/10.5067/ASTER/AST_07.003'
            },
            filter: {
              op: '=',
              args: [{ property: 'product:type' }, 'AST_07']
            }
          },
          {
            type: 'product',
            id: 'product:AST_08',
            label: 'ASTER Level-2 Surface Kinetic Temperature',
            info: {
              doi: 'https://doi.org/10.5067/ASTER/AST_08.003'
            },
            filter: {
              op: '=',
              args: [{ property: 'product:type' }, 'AST_08']
            }
          },
          {
            type: 'product',
            id: 'product:AST_09',
            label: 'ASTER Level-2 Surface Radiance (VNIR & SWIR)',
            info: {
              doi: 'https://doi.org/10.5067/ASTER/AST_09.003'
            },
            filter: {
              op: '=',
              args: [{ property: 'product:type' }, 'AST_09']
            }
          },
          {
            type: 'product',
            id: 'product:AST_09T',
            label: 'ASTER Level-2 Surface Radiance (TIR)',
            info: {
              doi: 'https://doi.org/10.5067/ASTER/AST_09T.003'
            },
            filter: {
              op: '=',
              args: [{ property: 'product:type' }, 'AST_09T']
            }
          }
        ]
      },
      {
        type: 'productCategory',
        id: 'platform:GF-1:instruments:WFV',
        label: 'GF-1 | WFV',
        tooltip: '高分一号 16m宽幅相机',
        children: [
          {
            type: 'product',
            id: 'product:GF1_WFV',
            label: 'GF-1 16米多波段影像',
            info: {
              doi: 'http://114.116.226.59/chinese/satellite/chinese/gf1'
            }
          }
        ]
      },
      {
        type: 'productCategory',
        id: 'platform:GF-1:instruments:PMS',
        label: 'GF-1 | PMS',
        tooltip: '高分一号 2m全色/8m多光谱相机',
        children: [
          {
            type: 'product',
            id: 'product:GF1_PMS',
            label: 'GF-1 2米全色/8米多光谱影像',
            info: {
              doi: 'http://114.116.226.59/chinese/satellite/chinese/gf1'
            }
          },
          {
            type: 'product',
            id: 'product:GF1B_PMS',
            label: 'GF-1B 2米全色/8米多波段影像',
            info: {
              doi: 'http://114.116.226.59/chinese/satellite/chinese/gf1bcd'
            }
          },
          {
            type: 'product',
            id: 'product:GF1C_PMS',
            label: 'GF-1C 2米全色/8米多波段影像',
            info: {
              doi: 'http://114.116.226.59/chinese/satellite/chinese/gf1bcd'
            }
          },
          {
            type: 'product',
            id: 'product:GF1D_PMS',
            label: 'GF-1D 2米全色/8米多波段影像',
            info: {
              doi: 'http://114.116.226.59/chinese/satellite/chinese/gf1bcd'
            }
          }
        ]
      },
      {
        type: 'productCategory',
        id: 'platform:GF-2:instruments:PMS',
        label: 'GF-2 | PMS',
        tooltip: '高分二号 1m全色/4m多光谱相机',
        children: [
          {
            type: 'product',
            id: 'product:GF2_PMS',
            label: 'GF-2 1米全色/4米多波段影像',
            info: {
              doi: 'http://114.116.226.59/chinese/satellite/chinese/gf2'
            }
          }
        ]
      },
      {
        type: 'productCategory',
        id: 'platform:GF-4:instruments:IRS_PMS',
        label: 'GF-4 | IRS + PMS',
        tooltip: '高分四号 50米全色/400米中红外凝视相机',
        children: [
          {
            type: 'product',
            id: 'product:GF4_IRS',
            label: 'GF-4 400米中红外影像',
            info: {
              doi: 'http://114.116.226.59/chinese/satellite/chinese/gf4'
            }
          },
          {
            type: 'product',
            id: 'product:GF4_PMI',
            label: 'GF-4 50米全色/多波段影像',
            info: {
              doi: 'http://114.116.226.59/chinese/satellite/chinese/gf4'
            }
          }
        ]
      }
    ]
  },
  {
    type: 'productCategory',
    id: 'sensorType:SAR',
    label: 'SAR',
    tooltip: '合成孔径雷达影像',
    children: [
      {
        type: 'productCategory',
        id: 'platform:SENTINEL-1:instruments:SAR',
        label: 'Sentinel-1 | C-SAR',
        tooltip: '哨兵一号 C波段合成孔径雷达',
        children: [
          {
            type: 'product',
            id: 'product:RAW__0S',
            label: 'Sentinel-1 Level-0 RAW Product',
            filter: {
              op: 'and',
              args: [
                {
                  op: 'in',
                  args: [
                    { property: 'platform' },
                    ['sentinel-1a', 'sentinel-1b']
                  ]
                },
                {
                  op: '=',
                  args: [{ property: 'product:type' }, 'RAW']
                }
              ]
            }
          },
          {
            type: 'product',
            id: 'product:GRD__1S',
            label: 'Sentinel-1 Level-1 GRD Product',
            filter: {
              op: 'and',
              args: [
                {
                  op: 'in',
                  args: [
                    { property: 'platform' },
                    ['sentinel-1a', 'sentinel-1b']
                  ]
                },
                {
                  op: '=',
                  args: [{ property: 'product:type' }, 'GRD']
                }
              ]
            }
          },
          {
            type: 'product',
            id: 'product:SLC__1S',
            label: 'Sentinel-1 Level-1 SLC Product',
            filter: {
              op: 'and',
              args: [
                {
                  op: 'in',
                  args: [
                    { property: 'platform' },
                    ['sentinel-1a', 'sentinel-1b']
                  ]
                },
                {
                  op: '=',
                  args: [{ property: 'product:type' }, 'SLC']
                }
              ]
            }
          },
          {
            type: 'product',
            id: 'product:OCN__2S',
            label: 'Sentinel-1 Level-2 OCN Product',
            filter: {
              op: 'and',
              args: [
                {
                  op: 'in',
                  args: [
                    { property: 'platform' },
                    ['sentinel-1a', 'sentinel-1b']
                  ]
                },
                {
                  op: '=',
                  args: [{ property: 'product:type' }, 'OCN']
                }
              ]
            }
          }
        ]
      },
      {
        type: 'productCategory',
        id: 'platform:TerraSAR-X:instruments:SAR',
        label: 'TerraSAR-X | X-SAR',
        children: [
          {
            type: 'product',
            id: 'product:TSX-1.SAR.L1b-Spotlight',
            label: 'TerraSAR-X Spotlight'
          },
          {
            type: 'product',
            id: 'product:TSX-1.SAR.L1b-Staring-Spotlight',
            label: 'TerraSAR-X Staring Spotlight'
          },
          {
            type: 'product',
            id: 'product:TSX-1.SAR.L1b-ScanSAR',
            label: 'TerraSAR-X ScanSAR'
          }
        ]
      },
      {
        type: 'productCategory',
        id: 'platform:EnviSat:instruments:ASAR',
        label: 'EnviSat | ASAR'
      },
      {
        type: 'productCategory',
        id: 'platform:GF-3:instruments:SAR',
        label: 'GF-3 | C-SAR'
      }
    ]
  },
  {
    type: 'productCategory',
    id: 'sensorType:ALT',
    label: 'ALT',
    tooltip: '激光雷达/高度计影像',
    children: [
      {
        type: 'productCategory',
        id: 'platform:IceSat-2:instrument:ATLAS',
        label: 'IceSat-2 | ATLAS',
        children: []
      }
    ]
  },
  {
    type: 'productCategory',
    id: 'sensorType:ATM',
    label: 'ATM',
    tooltip: '大气遥感影像',
    children: [
      {
        type: 'productCategory',
        id: 'platform:SENTINEL-5P:instruments:TROPOMI',
        label: 'Sentinel-5P | TROPOMI'
      },
      {
        type: 'productCategory',
        id: 'platform:GF-5:instruments:EMI',
        label: 'GF-5 | EMI'
      },
      {
        type: 'productCategory',
        id: 'platform:GF-5:instruments:GMI',
        label: 'GF-5 | GMI'
      },
      {
        type: 'productCategory',
        id: 'platform:GF-5:instruments:DPC',
        label: 'GF-5 | DPC'
      },
      {
        type: 'productCategory',
        id: 'platform:GF-5:instruments:AIMS',
        label: 'GF-5 | AIMS'
      }
    ]
  },
  {
    type: 'productCategory',
    id: 'sensorType:SSP',
    label: 'SSP',
    tooltip: '多卫星/多天合成专题影像',
    children: [
      {
        type: 'productCategory',
        id: 'productCategory:maxar_open_data',
        label: 'Maxar Open Data',
        tooltip: 'Maxar开放专题影像数据',
        children: []
      }
    ]
  }
];

export const PAGESIZE = 20;

export interface Product {
  type: 'productCategory' | 'product';
  id: string;
  label: string;
  tooltip?: string;
  applyCloudCover?: boolean;
  info?: {
    doi?: string;
    additionalFilterSchema?: string;
    [key: string]: any;
  };
  filter?: {
    op: string;
    args: any[];
  };
  additionalFilters?: Filter[]; //put additional filters under each product
  children?: Product[];
}

export type Filter = {
  selectedField: string;
  formValues: any;
  fieldSchema: any;
};

export { ZOOM_LEVELS } from '../components/EDSCTimeline/constants';
