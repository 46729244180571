import PropTypes from 'prop-types';
import { createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'store';
import { initialize, selectAuthState } from '../authSlice';
import {
  useLazyInitializeAuthQuery,
  useLoginMutation,
  useLogoutMutation,
  useRegisterUserMutation
} from '../authApi.ts';

// const verifiedAPI = process.env.REACT_APP_VERIFIED_API;

// // Anonymous user placeholder
// const anonymousUser = {
//   id: 'labelearth',
//   email: 'labelearth@amazon.com',
//   name: 'LabelEarth'
// };

// Initial state
const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  authToken: null
};

// Context for authentication
export const PythonBackendAuthContext = createContext({
  ...initialState,
  platform: 'PythonBackend',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  registerUser: () => Promise.resolve()
});

// Provider component
export const PythonBackendAuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const state = useSelector(selectAuthState);

  // Initialize the authentication state
  const [initializeAuth] = useLazyInitializeAuthQuery();

  useEffect(() => {
    const token = localStorage.getItem('authToken');

    if (token) {
      initializeAuth(token);
    } else {
      dispatch(
        initialize({
          isAuthenticated: false,
          user: null,
          authToken: null
        })
      );
    }
  }, [dispatch, initializeAuth]);

  // Login function
  const [login] = useLoginMutation();

  // Logout function
  const [logout] = useLogoutMutation();

  // Register User function
  const [registerUser] = useRegisterUserMutation();

  return (
    <PythonBackendAuthContext.Provider
      value={{
        ...state,
        login: (args) => login(args).unwrap(),
        logout: () => logout().unwrap(),
        registerUser: (args) => registerUser(args).unwrap(),
        platform: 'PythonBackend'
      }}
    >
      {state.isInitialized && children}
    </PythonBackendAuthContext.Provider>
  );
};

PythonBackendAuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
