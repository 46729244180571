import { Skeleton, Box, Typography } from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';

export const ImageWithSkeleton = (props) => {
  const { src, width, alt } = props;
  const [isDone, setDone] = useState(false);
  const [isError, setError] = useState(false);

  return (
    <Box
      width={width}
      height={width}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {/* {!src && (
        <Typography variant="caption" color="textSecondary">
          No image available
        </Typography>
      )} */}
      {!isDone && !isError && (
        <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
      )}
      {isError && src && (
        <Typography variant="caption" color="grey">
          Failed to retrieve image
        </Typography>
      )}
      {src && !isError && (
        <img
          onLoad={() => setDone(true)}
          onError={() => {
            setDone(true);
            setError(true);
          }}
          alt={alt || 'Image'}
          width={isDone ? width : 0}
          style={{
            display: isDone ? 'block' : 'none',
            border: '1px solid #ddd',
            borderRadius: '4px'
          }}
          {...props}
        />
      )}
    </Box>
  );
};

ImageWithSkeleton.propTypes = {
  width: PropTypes.string.isRequired,
  alt: PropTypes.string,
  src: PropTypes.string
};
