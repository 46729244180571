import { PythonBackendAuthProvider } from './PythonBackendAuthContext';
import { LocalAuthProvider } from './LocalAuthContext';

// export const AuthProvider = !process.env.REACT_APP_CUSTOM_AUTH
//   ? !process.env.REACT_APP_COGNITO_USER_POOL_ID
//     ? LocalAuthProvider
//     : CognitoAuthProvider
//   : OidcAuthProvider;

export const AuthProvider = !process.env.REACT_APP_USER_API
  ? LocalAuthProvider
  : PythonBackendAuthProvider;
