import { lazy, Suspense } from 'react';
import { EmptyLayout, LoadingScreen } from 'design';
import { AuthGuard, GuestGuard } from 'authentication';
import { Login } from 'authentication/views/Login.js';
import { Navigate } from 'react-router';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Error pages
const NotFound = Loadable(
  lazy(() => import('./modules/NotFound/views/NotFound'))
);

// remote sensing image search router
const SearchImage = Loadable(
  lazy(() => import('./modules/EODAG/views/SearchImage'))
);

const FavoriteListView = Loadable(
  lazy(() => import('./modules/EODAG/views/FavoriteListView.js'))
);

const FavoriteView = Loadable(
  lazy(() => import('./modules/EODAG/views/FavoriteView.js'))
);

const LabelStudioView = Loadable(
  lazy(() => import('./modules/LabelStudio/views/LabelStudio.js'))
);

const ToolBoxView = Loadable(
  lazy(() => import('./modules/GeoToolBox/views/leafmap.js'))
);

const GeoJournalView = Loadable(
  lazy(() => import('./modules/GeoJournal/views/RepoExplorer.js'))
);

const AutoSolverView = Loadable(
  lazy(() => import('./modules/AutoSolver/views/AutoSolver.js'))
);

const routes = [
  {
    path: 'login',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    )
  },
  {
    path: '*',
    element: (
      <AuthGuard>
        <EmptyLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'favorite',
        element: <FavoriteListView />
      },
      {
        path: 'favorite/:favoriteID',
        element: <FavoriteView />
      },
      {
        path: 'labelling',
        element: <LabelStudioView />
      },
      {
        path: 'geotoolbox',
        element: <ToolBoxView />
      },
      {
        path: 'geojournal/repos/:owner/:repo/*',
        element: <GeoJournalView />
      },
      {
        path: 'autosolver',
        element: <AutoSolverView />
      },
      {
        path: 'search',
        element: <SearchImage />
      },
      {
        path: '',
        element: <Navigate to="/search" />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
