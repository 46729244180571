import { createSlice } from '@reduxjs/toolkit';

// Redux slice to handle authentication state
// sync state with local storage in reducers
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isInitialized: false,
    isAuthenticated: false,
    user: null,
    authToken: null
  },
  reducers: {
    userLogin: (state, action) => {
      const { user, authToken } = action.payload;
      state.isAuthenticated = true;
      state.user = user;
      state.authToken = authToken;
      localStorage.setItem('authToken', authToken);
    },
    userLogout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.authToken = null;
      localStorage.removeItem('authToken');
    },
    initialize: (state, action) => {
      const { isAuthenticated, user, authToken } = action.payload;
      state.isInitialized = true;
      state.isAuthenticated = isAuthenticated;
      state.user = user;
      state.authToken = authToken;
      if (isAuthenticated && authToken) {
        localStorage.setItem('authToken', authToken);
      } else {
        localStorage.removeItem('authToken');
      }
    }
  },
  selectors: {
    selectAuthState: (state) => state
  }
});

export const { userLogin, userLogout, initialize, setAuthToken } =
  authSlice.actions;

export const { selectAuthState } = authSlice.selectors;

export default authSlice.reducer;
