import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks';

export const AuthGuard = (props) => {
  const { children } = props;
  const auth = useAuth();

  if (!auth.isAuthenticated) {
    // TODO: relogin like <ReAuthModal />
    return <Navigate to="/login" />;
  }
  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};
