import { createApi } from '@reduxjs/toolkit/query/react';
import { authorizedBaseQuery } from 'authentication';
import { formatSearch } from './stacUtils.ts';
import { SearchCriteria, setSearchId } from './searchSlice.ts';

const favouriteApiURL = process.env.REACT_APP_FAVOURITE_API;

type SearchLog = {
  id: number;
  user_id: number;
  collections: string;
  name: string;
  tags: string;
  form_json: any;
};

type FavouriteInfo = {
  id: number;
  name: string;
  tags: string;
  user_id: number;
  collections: string;
  form_json: any;
  create_time?: string;
};

type FavouritePayload = {
  search_id: number;
  name: string;
  tags: string;
  include_item_ids: string[];
};

export const favouriteApi = createApi({
  reducerPath: 'favouriteApi',
  baseQuery: authorizedBaseQuery({ baseUrl: favouriteApiURL }),
  tagTypes: ['SearchLog', 'Favourites'],
  endpoints: (builder) => ({
    // manage search logs
    getSearchLogList: builder.query<{ list: SearchLog[]; total: number }, void>(
      {
        query: () => '/SearchLog/GetSearchLog',
        providesTags: ['SearchLog']
      }
    ),
    addSearchLog: builder.mutation<
      { id: number },
      { searchCriteria: SearchCriteria }
    >({
      query: ({ searchCriteria }) => {
        const searchParameters = formatSearch({ searchCriteria });

        return {
          url: '/SearchLog/addSearchLog',
          method: 'POST',
          body: {
            form_json: searchCriteria,
            cql_json: searchParameters
          }
        };
      },
      invalidatesTags: ['SearchLog'],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setSearchId(data.id));
        } catch {
          dispatch(setSearchId(null));
        }
      }
    }),
    deleteSearchLog: builder.mutation<void, number>({
      query: (searchLogId) => ({
        url: `/SearchLog/DelSearchLog/${searchLogId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['SearchLog']
    }),

    // manage favourites
    getFavouriteList: builder.query<
      { list: FavouriteInfo[]; total: number },
      void
    >({
      query: () => `/FavoritesResult/GetFavoritesResult`,
      providesTags: ['Favourites'] // Provide the Favourites tag for this query
    }),

    getFavouriteCollectionInfoById: builder.query<
      { favoritesResult: FavouriteInfo },
      { favoriteID: number }
    >({
      query: ({ favoriteID }) =>
        `/FavoritesResult/GetFavoritesResultById?collection_id=${favoriteID}`,
      providesTags: ['Favourites'] // Provide the Favourites tag for this query
    }),

    getFavouriteItems: builder.query<{ ids: string[] }, { favoriteID: number }>(
      {
        query: ({ favoriteID }) =>
          `/FavoritesResult/GetFavoritesItem?collection_id=${favoriteID}`,
        providesTags: ['Favourites']
      }
    ),

    getFavouriteTemporalAggregation: builder.query<
      {
        searchId: string;
        label: string;
        startTime: string;
        endTime: string;
        count: number;
      }[],
      { favoriteID: number; temporalGranularity: string }
    >({
      query: ({ favoriteID, temporalGranularity }) =>
        `/Aggregations/favorite_aggregations?collection_id=${favoriteID}&granularity=${temporalGranularity}`,
      providesTags: ['Favourites']
    }),

    getFavouriteSpatialAggregationByGrid: builder.query({
      query: ({ favoriteID, spatialGridDegree }) =>
        `/Aggregations/grid_aggregations?collection_id=${favoriteID}&degree=${spatialGridDegree}`,
      providesTags: ['Favourites']
    }),

    addFavourite: builder.mutation<{ id: number }, FavouritePayload>({
      query: ({ search_id, name, tags, include_item_ids }) => {
        return {
          url: '/FavoritesResult/addFavoritesResult',
          method: 'POST',
          body: {
            search_id,
            name,
            tags,
            include_item_ids
          }
        };
      },
      invalidatesTags: ['Favourites'] // Invalidate the Favourites tag after mutation
    }),
    renameFavorite: builder.mutation<
      void,
      { entryId: number; name: string; tags: string }
    >({
      query: ({ entryId, name, tags }) => {
        return {
          url: `/FavoritesResult/updateFavoritesResult/${entryId}`,
          method: 'POST',
          body: { name, tags }
        };
      },
      invalidatesTags: ['Favourites'] // Invalidate the Favourites tag after mutation
    }),
    deleteFavorite: builder.mutation<void, number>({
      query: (entryId) => {
        return {
          url: `/FavoritesResult/DelFavoritesResult/${entryId}`,
          method: 'DELETE'
        };
      },
      invalidatesTags: ['Favourites'] // Invalidate the Favourites tag after mutation
    }),

    getAssetImage: builder.query({
      query: (asset) => ({
        url: '/Gateway/getImage',
        method: 'POST',
        body: { content: asset },
        responseHandler: (response) => response.arrayBuffer()
      }),
      transformResponse: (response: ArrayBuffer) => {
        const base64 = btoa(
          new Uint8Array(response).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ''
          )
        );
        return `data:image/jpeg;base64,${base64}`;
      }
    })
  })
});

export const {
  useGetSearchLogListQuery,
  useAddSearchLogMutation,
  useGetFavouriteListQuery,
  useGetFavouriteCollectionInfoByIdQuery,
  useGetFavouriteItemsQuery,
  useGetFavouriteTemporalAggregationQuery,
  useGetFavouriteSpatialAggregationByGridQuery,
  useAddFavouriteMutation,
  useRenameFavoriteMutation,
  useDeleteFavoriteMutation,
  useGetAssetImageQuery
} = favouriteApi;
