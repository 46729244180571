export const AwsRegions = [
  { name: 'US East (Ohio)', code: 'us-east-2' },
  { name: 'US East (N. Virginia)', code: 'us-east-1' },
  { name: 'US West (N. California)', code: 'us-west-1' },
  { name: 'US West (Oregon)', code: 'us-west-2' },
  { name: 'Africa (Cape Town)', code: 'af-south-1' },
  { name: 'Asia Pacific (Hong Kong)', code: 'ap-east-1' },
  { name: 'Asia Pacific (Mumbai)', code: 'ap-south-1' },
  { name: 'Asia Pacific (Osaka-Local)', code: 'ap-northeast-3' },
  { name: 'Asia Pacific (Seoul)', code: 'ap-northeast-2' },
  { name: 'Asia Pacific (Singapore)', code: 'ap-southeast-1' },
  { name: 'Asia Pacific (Sydney)', code: 'ap-southeast-2' },
  { name: 'Asia Pacific (Tokyo)', code: 'ap-northeast-1' },
  { name: 'Canada (Central)', code: 'ca-central-1' },
  { name: 'China (Beijing)', code: 'cn-north-1' },
  { name: 'China (Ningxia)', code: 'cn-northwest-1' },
  { name: 'Europe (Frankfurt)', code: 'eu-central-1' },
  { name: 'Europe (Ireland)', code: 'eu-west-1' },
  { name: 'Europe (London)', code: 'eu-west-2' },
  { name: 'Europe (Milan)', code: 'eu-south-1' },
  { name: 'Europe (Paris)', code: 'eu-west-3' },
  { name: 'Europe (Stockholm)', code: 'eu-north-1' },
  { name: 'Middle East (Bahrain)', code: 'me-south-1' },
  { name: 'South America (São Paulo)', code: 'sa-east-1' },
  { name: 'AWS GovCloud (US-East)', code: 'us-gov-east-1' },
  { name: 'AWS GovCloud (US)', code: 'us-gov-west-1' }
];

export const repo = {
  owner: 'Label-Earth-Group',
  repo: 'geospatial-usecase'
};

export const solverURL = 'http://18.236.186.120:8086'; //the address of the server started from code synced from LabelEarth/LLM-Geo

export const aiSearchApi = 'http://18.236.186.120:8001/chatgpt'; //the address of the server of AI-aided search

export const jupyterHubURL = 'http://35.89.148.41'; //the address of server for jupyter hub

export const leafmapURL = 'http://118.31.15.5:8088/'; //the address of server for voila serving leafmap

export const labelStudioURL = 'http://localhost:8080'; //the address for label studio server
export const labelStudioCookies =
  'sessionid=.eJxVj8FuhDAMRP8lZ0COE3DCsfd-A3JCgHRRsiIgtbvqvxeqPbRHz4yfx09xxFH0gtG6CYyvNYKrNUOorVVQe_LKG4LJShSVyNvMKT54jzkN95voZSVWLvuw5jmmcySpFCF02JhWgtVYiYGPfRmOErbh99TF-aM59reQLmP84DTnxue0b9E1V6R5uaV5z2NY317Zf4CFy3JuTwjaEzrjHY1A2jomOKuwUYYnnBiZSXZdy0S-M9Jr7bFFIquJObgLWkIp12fh8x63L9FrhQDw_QO6gFra:1rx8Xc:b5NtBqp1fYgmZLZZ3RAtIh7wiN66_PGnNAwYbODf5T0; expires=Mon, 22 Apr 2024 16:53:48 GMT; HttpOnly; Max-Age=432000; Path=/; SameSite=Lax';
