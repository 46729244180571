import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError
} from '@reduxjs/toolkit/query';
import {
  initialize,
  userLogin,
  userLogout,
  selectAuthState
} from './authSlice';
import { SET_ERROR, RootState } from 'store';

const userApiURL = process.env.REACT_APP_USER_API;

type AuthorizedResponseFormat = {
  code: number;
  data?: any;
  message?: string;
};

type UserInfo = {
  id: number;
  name: string;
  email: string;
  wechat?: string;
  mobile?: string;
};

/**
 * Creates an authorized base query function for API requests.
 * This function wraps fetchBaseQuery and adds authentication token handling.
 * It also processes the response to handle custom error codes from the backend.
 */
export const authorizedBaseQuery =
  ({
    baseUrl
  }: {
    baseUrl: string;
  }): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =>
  async (args, api, extraOptions) => {
    // take auth token from redux state and set it in the header
    const baseQuery = fetchBaseQuery({
      baseUrl: baseUrl,
      prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).auth.authToken;
        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
      }
    }) as BaseQueryFn<
      string | FetchArgs,
      AuthorizedResponseFormat | unknown,
      FetchBaseQueryError
    >;

    const { data, error } = await baseQuery(args, api, extraOptions);
    if (data) {
      // Check if data is of AuthorizedResponseFormat type
      if (typeof data === 'object' && 'code' in data) {
        const authorizedData = data as AuthorizedResponseFormat;
        if (authorizedData.code === 0) {
          return { data: authorizedData.data || authorizedData.message };
        } else {
          return {
            error: { status: authorizedData.code, data: authorizedData.message }
          };
        }
      } else {
        // If data is not of AuthorizedResponseFormat, return it as is
        return { data };
      }
    } else if (error) {
      // if the error is 401 unauthorized, logout the user
      if (error.status === 401) {
        api.dispatch(userLogout());
      }
      return { error };
    }
  };

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: authorizedBaseQuery({ baseUrl: userApiURL }),
  tagTypes: ['AuthVerification'],
  endpoints: (builder) => ({
    login: builder.mutation<
      { access_token: string; user_info: UserInfo },
      { username: string; password: string }
    >({
      query: ({ username, password }) => {
        const body = new URLSearchParams();
        body.append('username', username);
        body.append('password', password);

        return {
          url: '/Login',
          method: 'POST',
          body: body.toString(),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        };
      },
      async onQueryStarted(
        { username, password },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data } = await queryFulfilled;
          const { access_token: authToken, user_info: user } = data;

          if (!authToken || !user) {
            throw new Error('No token or user info received');
          }

          dispatch(userLogin({ user, authToken }));
        } catch (error) {
          console.error('Login failed:', error);
          dispatch({ type: SET_ERROR, error: error.message });
        }
      }
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: '/Logout',
        method: 'POST',
        body: { token: localStorage.getItem('authToken') }
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(userLogout());
        } catch (error) {
          console.error('Logout failed:', error);
          dispatch({ type: SET_ERROR, error: error.message });
        }
      }
    }),
    fetchUserInfo: builder.query<UserInfo, void>({
      query: () => ({
        url: `/GetUserInfo`,
        method: 'GET'
      }),
      providesTags: ['AuthVerification'],
      async onQueryStarted(_, { getState, dispatch, queryFulfilled }) {
        try {
          const { authToken } = selectAuthState(getState() as RootState);
          const { data } = await queryFulfilled;
          dispatch(userLogin({ user: data, authToken }));
        } catch (error) {
          dispatch(userLogout());
        }
      }
    }),
    initializeAuth: builder.query<UserInfo, string>({
      query: (authToken) => ({
        url: `/GetUserInfo`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }),
      providesTags: ['AuthVerification'],
      async onQueryStarted(authToken, { getState, dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            initialize({
              isAuthenticated: true,
              user: data,
              authToken: authToken
            })
          );
        } catch (error) {
          dispatch(
            initialize({
              isAuthenticated: false,
              user: null,
              authToken: null
            })
          );
        }
      }
    }),
    registerUser: builder.mutation<
      void,
      {
        username: string;
        password: string;
        email: string;
        wechat?: string;
        mobile?: string;
      }
    >({
      query: ({ username, password, email, wechat, mobile }) => {
        const body = new URLSearchParams();
        body.append('username', username);
        body.append('password', password);
        body.append('email', email);
        wechat && body.append('wechat', wechat);
        mobile && body.append('mobile', mobile);

        return {
          url: '/RegisterUser',
          method: 'POST',
          body: body,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        };
      }
    })
  })
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useFetchUserInfoQuery,
  useLazyFetchUserInfoQuery,
  useLazyInitializeAuthQuery,
  useRegisterUserMutation
} = authApi;
